<template>
    <SimpleTable formclass="Partner/form" endpoint="partners" :aditionalColumns="headers" inlineToggle>
        <template>
            <h1>Cadastro de Convênios</h1>
            <v-divider class="mb-4"></v-divider>
        </template>

        <template v-slot:extra-column="item"> {{ item.discount }} % </template>
    </SimpleTable>
</template>

<script>
import SimpleTable from "@/components/core/SimpleTable";
export default {
    components: {
        SimpleTable,
    },
    data() {
        return {
            headers: [
                { text: this.$t("Habilitado"), value: "enable", align: "right", sortable: false },
                { text: this.$t("Desconto"), value: "extra-column", align: "right", sortable: true },
                { text: this.$t("Nome"), value: "name", align: "right", sortable: true },
            ],
        };
    },
};
</script>